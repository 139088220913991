<template>
  <div>
    <b-card title="Liste des comptes validés">
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucun compte validé."
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(user)="data">
          {{ data.item.name + ' ' + data.item.surname }}
        </template>
        <template v-slot:cell(location)="data">
          {{ data.item.country + ' ( ' + data.item.city + ' )' }}
        </template>
        <template v-slot:cell(birthDate)="data">
          {{ data.item.birthDate | moment("DD-MM-YYYY") }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EyeIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-success"
              @click="openModal(data.item)"
            />
          </span>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>

    <b-modal
      v-if="currentUser"
      v-model="showMore"
      centered
      hide-footer
      size="lg"
      title="Information supplémentaire"
      :no-close-on-backdrop="loadFiles || validProcess"
    >
      <b-row>
        <b-col sm="12">
          <table class="table table-success">
            <tr>
              <td>
                <span class="text-italic">Telephone</span>
              </td>
              <td>
                {{ currentUser.phone }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Email
              </td>
              <td class="w-50">
                {{ currentUser.email }}
              </td>
            </tr>

            <tr v-if="currentUser.countIdFaceProofValid">
              <td class="w-50">
                Photo + Piece d'identité
              </td>
              <td class="w-50">
                VALIDE
              </td>
            </tr>
            <tr v-if="currentUser.countIdProofValid">
              <td class="w-50">
                Piece d'identité
              </td>
              <td class="w-50">
                VALIDE
              </td>
            </tr>

            <tr v-if="currentUser.countResidenceProofValid">
              <td class="w-50">
                Preuve de résidence
              </td>
              <td class="w-50">
                VALIDE
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr>
      <div>
        <div
          v-if="loadFiles || validProcess"
          class="mt-3 text-center"
        >
          <b-spinner
            label="Loading"
            variant="primary"
          />
        </div>
        <div class="float-left">
          <b-button
            variant="secondary"
            :disabled="loadFiles || validProcess"
            @click="closeModal"
          >Quitter</b-button>
        </div>

        <div class="float-right">
          <b-button
            v-if="currentUser.countResidenceProofValid && currentUser.countIdProofValid && currentUser.countIdFaceProofValid"
            variant="outline-success"
            :disabled="validProcess"
            @click="getUserById(currentUser.id)"
          >Voir les documents</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-if="currentUserFiles"
      v-model="showFiles"
      centered
      hide-footer
      title="Fichiers soumis a validation"
    >
      <b-card
        v-if="currentUserFiles.countIdProofValid"
        title="Piece d'identité"
        :img-src="currentUserFiles.validId[0].media.name"
        img-alt="Piece d'identité"
        img-top
        img-height="300"
        img-width="300"
        class="mb-3"
      >
        <b-card-text class="w-100d">

          <table class="w-100 table-responsive">
            <tr class="mb-2">
              <td class="w-100">
                <span class="text-italic">Nom</span>
              </td>
              <td class="w-100">
                {{ currentUserFiles.name }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Prenoms
              </td>
              <td class="w-50">
                {{ currentUserFiles.surname }}
              </td>
            </tr>

            <tr>
              <td class="w-50">
                Sexe
              </td>
              <td class="w-50">
                {{ currentUserFiles.sex }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Date de naissance
              </td>
              <td class="w-50">
                {{ currentUserFiles.birthDate | moment("DD-MM-YYYY") }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Telephone
              </td>
              <td class="w-50">
                {{ currentUserFiles.phone }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Email
              </td>
              <td class="w-50">
                {{ currentUserFiles.email }}
              </td>
            </tr>
          </table>
        </b-card-text>
      </b-card>

      <b-card
        v-if="currentUserFiles.countIdFaceProofValid"
        title="Piece d'identité + Selfie"
        :img-src="currentUserFiles.validFaceId[0].media.name"
        img-alt="Piece d'identité + Selfie - Photo"
        img-top
        img-height="300"
        img-width="300"
        class="mb-3"
      />

      <b-card
        v-if="currentUserFiles.countResidenceProofValid"
        title="Preuve de résidence"
        :img-src="currentUserFiles.validResidence[0].media.name"
        img-alt="Preuve de résidence"
        img-top
        img-height="300"
        img-width="300"
        class="mb-3"
      >
        <b-card-text class="w-100">
          <table class="w-100 table-responsive">
            <tr class="mb-2">
              <td class="w-100">
                <span class="text-italic">Pays</span>
              </td>
              <td class="w-100">
                {{ currentUserFiles.country }}
              </td>
            </tr>
            <tr>
              <td class="w-50">
                Ville
              </td>
              <td class="w-50">
                {{ currentUserFiles.city }}
              </td>
            </tr>

            <tr>
              <td class="w-50">
                Adresse
              </td>
              <td class="w-50">
                {{ currentUserFiles.address }}
              </td>
            </tr>
          </table>
        </b-card-text>
      </b-card>

    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  data() {
    return {
      axiosCancelSource: null,
      motif: null,
      showRejectModal: false,
      showValidModal: false,
      showMore: false,
      loaded: false,
      showFiles: false,
      loadFiles: false,
      currentUser: null,
      currentUserFiles: null,
      currentPage: 1,
      perPage: 10,
      currentAction: {
        id: null,
        file: null,
        action: null,
      },
      items: [],
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'user', label: 'Utilisateur' },
        { key: 'birthDate', label: 'Date de naissance' },
        { key: 'sex', label: 'Sexe' },
        { key: 'location', label: 'Adresse' },
        { key: 'action', label: 'Detail', class: 'text-center' },
      ],
      totalItems: 0,
    }
  },
  validations: {
    motif: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      token: 'currentToken',
      faceProcess: 'faceIdentityProcess',
      faceError: 'faceIdentityError',
      faceSuccess: 'faceIdentitySuccess',

      idProcess: 'identityProcess',
      idError: 'identityError',
      idSuccess: 'identitySuccess',

      resProcess: 'residencyProcess',
      resError: 'residencyError',
      resSuccess: 'residencySuccess',

      validProcess: 'validAccountProcess',
      validError: 'validAccountError',
      validSuccess: 'validAccountSuccess',
    }),
  },
  watch: {
    validSuccess(val) {
      if (val) {
        this.closeModal()
        this.getValidUsers()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de compte',
            text: 'Compte validé avec succès',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    validError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation de compte',
            text: 'Une erreur est survenue lors de la validation. Veuillez reprendre.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    faceSuccess(val) {
      if (val) {
        this.closeModal()
        this.getValidUsers()
        if (this.currentAction.action === 'REJECT') {
          this.closeRejectModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rejet du selfie',
              text: 'Rejet fait avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.closeValidModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validation du selfie',
              text: 'Validation faite avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      }
    },
    faceError(val) {
      if (val) {
        if (this.currentAction.action === 'REJECT') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rejet du selfie',
              text: 'Une erreur est survenue lors du rejet.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validation du selfie',
              text: 'Une erreur est survenue lors de la validation.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },

    idSuccess(val) {
      if (val) {
        this.closeModal()
        this.getValidUsers()
        if (this.currentAction.action === 'REJECT') {
          this.closeRejectModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Rejet de la piece d'identite",
              text: 'Rejet fait avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.closeValidModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Validation de la piece d'identite",
              text: 'Validation faite avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      }
    },
    idError(val) {
      if (val) {
        if (this.currentAction.action === 'REJECT') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Rejet de la piece d'identite",
              text: 'Une erreur est survenue lors du rejet.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Validation de la piece d'identite",
              text: 'Une erreur est survenue lors de la validation.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },

    resSuccess(val) {
      if (val) {
        this.getValidUsers()
        this.closeModal()
        this.getUserById(this.currentUserFiles.id)
        if (this.currentAction.action === 'REJECT') {
          this.closeRejectModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rejet de la preuve de résidence',
              text: 'Rejet fait avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.closeValidModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validation de la preuve de résidence',
              text: 'Validation faite avec succès',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }
      }
    },
    resError(val) {
      if (val) {
        if (this.currentAction.action === 'REJECT') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rejet de la preuve de résidence',
              text: 'Une erreur est survenue lors du rejet.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validation de la preuve de résidence',
              text: 'Une erreur est survenue lors de la validation.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
  mounted() {
    const { CancelToken } = axios
    this.axiosCancelSource = CancelToken.source()
    this.getValidUsers()
  },
  beforeDestroy() {
    this.axiosCancelSource.cancel('Axios request canceled.')
  },
  methods: {
    ...mapActions(['treatProofOfResidency', 'treatProofOfIdentity', 'treatProofOfFace', 'validAccount']),
    tryToTreat() {
      if (this.currentAction.file === 'POI') {
        // eslint-disable-next-line default-case
        switch (this.currentAction.action) {
          case 'REJECT':
            this.$v.$touch()
            if (this.$v.$error) return

            this.treatProofOfIdentity({
              id: this.currentAction.id,
              state: 'REJECTED',
              motif: this.motif,
            })
            break
          case 'VALID':
            this.treatProofOfIdentity({
              id: this.currentAction.id,
              state: 'VALID',
              motif: null,
            })
            break
        }
      }
      if (this.currentAction.file === 'POR') {
        // eslint-disable-next-line default-case
        switch (this.currentAction.action) {
          case 'REJECT':
            this.$v.$touch()
            if (this.$v.$error) return

            this.treatProofOfResidency({
              id: this.currentAction.id,
              state: 'REJECTED',
              motif: this.motif,
            })
            break
          case 'VALID':
            this.treatProofOfResidency({
              id: this.currentAction.id,
              state: 'VALID',
              motif: null,
            })
            break
        }
      }
      if (this.currentAction.file === 'POF') {
        // eslint-disable-next-line default-case
        switch (this.currentAction.action) {
          case 'REJECT':
            this.$v.$touch()
            if (this.$v.$error) return

            this.treatProofOfFace({
              id: this.currentAction.id,
              state: 'REJECTED',
              motif: this.motif,
            })
            break
          case 'VALID':
            this.treatProofOfFace({
              id: this.currentAction.id,
              state: 'VALID',
              motif: null,
            })
            break
        }
      }
    },
    tryToValid(item) {
      this.validAccount({
        id: item,
      })
    },
    openModal(item) {
      this.currentUser = item
      this.showMore = true
    },
    openValidModal(fileType, action, fileId) {
      this.currentAction = {
        id: fileId,
        action,
        file: fileType,
      }
      this.showValidModal = true
    },
    closeValidModal() {
      this.showValidModal = false
    },
    openRejectModal(fileType, action, fileId) {
      this.currentAction = {
        id: fileId,
        action,
        file: fileType,
      }
      this.showRejectModal = true
    },
    closeRejectModal() {
      this.showRejectModal = false
    },
    closeModal() {
      this.showMore = false
      this.currentUser = null
    },
    async getUserById(item) {
      this.loadFiles = true
      this.currentUserFiles = await this.$http.get(`/find-by-id/${item}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        this.loadFiles = false
        this.showFiles = true
        return res.data
      })
        .catch(() => {
          this.showFiles = false
          this.loadFiles = false
          return null
        })
    },
    async getValidUsers() {
      this.loaded = true
      this.items = await this.$http.get(`/user-by-state/VALID/${this.perPage}?page=${this.currentPage}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        const { data } = res
        this.loaded = false
        // this.totalItems = data.meta.total
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Comptes',
            text: data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        return data.data.data
      })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>
